const routes = [
  {
    path: '/contractors/invite',
    name: 'Invite',
    meta: {
      requiresAuth: true,
      jobCreateBtn: true,
      title: 'Invite'
    },
    component: () => import('@/views/Invite.vue')
  }
];

export default routes;
