import type { AppState } from '@/types/appState';
import { SortByOptions } from '@shared/generated/models/SortByOptions';
import { SortDirectionOptions } from '@shared/generated/models/SortDirectionOptions';
import { defineStore } from 'pinia';

export const useAppStore = defineStore({
  id: 'appState',
  state: (): AppState => ({
    errorType: null,
    user_type: 'contractor',
    contractor: null,
    loading: false, // TODO: move all component loading states here
    sidebarOpen: !localStorage.getItem('sidebarOpen')
      ? true
      : localStorage.getItem('sidebarOpen') === 'true',
    jobSorting: {
      sortBy: SortByOptions.CREATED_AT,
      sortDirection: SortDirectionOptions.DESC
    },
    userPreferences:
      JSON.parse(localStorage.getItem('userPreferences') as string) || null
  }),
  actions: {
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
      localStorage.setItem('sidebarOpen', this.sidebarOpen.toString());
    },
    setUserType(user_type: AppState['user_type']) {
      this.user_type = user_type;
    },
    setContractor(contractor: AppState['contractor']) {
      this.contractor = contractor;
    },
    setUserPreferences(userPreferences: AppState['userPreferences']) {
      this.userPreferences = userPreferences;
      if (userPreferences)
        localStorage.setItem(
          'userPreferences',
          JSON.stringify(this.userPreferences)
        );
    },
    setErrorType(errorType: AppState['errorType']) {
      this.errorType = errorType;
    }
  }
});
