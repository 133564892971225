<template>
  <div class="d-flex flex-column fill-viewport">
    <div class="position-relative flex-fill d-flex slot-layout">
      <div class="container p-0">
        <slot />
      </div>
    </div>
  </div>
  <div id="modal-target"></div>
</template>
<script setup lang="ts"></script>
<style lang="scss" scoped>
.slot-layout {
  background-color: #fff;
}
</style>
