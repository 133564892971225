<template>
  <main class="container text-center mt-5">
    <div class="col">
      <!-- slot for svg -->
      <slot name="image"></slot>

      <h4 class="mb-2">{{ props.heading }}</h4>
      <h5
        class="fw-normal mb-4"
        v-if="props.subheading"
      >
        {{ props.subheading }}
      </h5>
      <p class="text-gray mb-4">
        {{ props.description }}
      </p>

      <a
        href="mailto:support@sealed.com"
        class="btn btn-outline-secondary mb-3 px-5"
      >
        {{ t('common_actions.contact') }}
      </a>

      <a
        href="/"
        class="d-flex align-items-center justify-content-center text-small text-primary"
      >
        {{ t('common_actions.return_to_job_list') }}
        <i class="arrow material-symbols-outlined">chevron_right</i>
      </a>
    </div>
  </main>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  heading: string;
  subheading?: string;
  description: string;
}>();
</script>
