import LogRocket from 'logrocket';
import type { App } from 'vue';

export default {
  install: (app: App, { appId }: { appId: string }) => {
    LogRocket.init(appId, {
      network: {
        requestSanitizer: (request) => {
          // Don't send any request bodies to LogRocket if it's a request to the Firebase Auth API
          if (request.url.includes('identitytoolkit.googleapis.com')) {
            request.body = '';
          }
          if (request.headers['Authorization']) {
            delete request.headers['Authorization'];
          }
          return request;
        }
      }
    });
  }
};

let hasIdentifiedUser = false;
export function identifyUser(uid: string, params?: object) {
  if (hasIdentifiedUser) return;
  hasIdentifiedUser = true;
  LogRocket.identify(uid, { ...params });
}

export function logRocketEvent(eventName: string, params?: object) {
  LogRocket.log(eventName, { ...params });
}
