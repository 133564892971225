/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContractorCreateRequest } from '../models/ContractorCreateRequest';
import type { ContractorCurrentResponse } from '../models/ContractorCurrentResponse';
import type { ContractorFilterRequest } from '../models/ContractorFilterRequest';
import type { ContractorFilterResponse } from '../models/ContractorFilterResponse';
import type { ContractorResponse } from '../models/ContractorResponse';
import type { ContractorSearchResponse } from '../models/ContractorSearchResponse';
import type { ContractorUpdateRequest } from '../models/ContractorUpdateRequest';
import type { ContractorUserInviteRequest } from '../models/ContractorUserInviteRequest';
import type { FilterItem } from '../models/FilterItem';
import type { PaginatedResponse_ContractorListResponse_ } from '../models/PaginatedResponse_ContractorListResponse_';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ContractorRoutesRepository {
  /**
   * Contractor Search Inputs
   * Gets a list of contractors with the response limited to omni-search relevant fields.
   * @returns ContractorSearchResponse Successful Response
   * @throws ApiError
   */
  public static contractorRoutesContractorSearchInputs({
    authorization,
  }: {
    authorization?: string,
  }): CancelablePromise<Array<ContractorSearchResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/contractor/search',
      headers: {
        'authorization': authorization,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Contractors Simple List
   * Get list of contractors with just id and name.
   * @returns FilterItem Successful Response
   * @throws ApiError
   */
  public static contractorRoutesGetContractorsSimpleList({
    authorization,
  }: {
    authorization?: string,
  }): CancelablePromise<Array<FilterItem>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/contractor/simple-list',
      headers: {
        'authorization': authorization,
      },
      errors: {
        404: `Not Found`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get All
   * Lists all contractors.
   * @returns PaginatedResponse_ContractorListResponse_ Successful Response
   * @throws ApiError
   */
  public static contractorRoutesGetAll({
    programId,
    pageNumber = 1,
    perPage = 25,
    authorization,
  }: {
    /**
     * List of Program IDs
     */
    programId?: (Array<string> | null),
    pageNumber?: number,
    perPage?: number,
    authorization?: string,
  }): CancelablePromise<PaginatedResponse_ContractorListResponse_> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/contractor',
      headers: {
        'authorization': authorization,
      },
      query: {
        'program_id': programId,
        'page_number': pageNumber,
        'per_page': perPage,
      },
      errors: {
        404: `Not Found`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Create
   * Creates a new contractor.
   * @returns ContractorResponse Successful Response
   * @throws ApiError
   */
  public static contractorRoutesCreate({
    requestBody,
    authorization,
  }: {
    requestBody: ContractorCreateRequest,
    authorization?: string,
  }): CancelablePromise<ContractorResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/contractor',
      headers: {
        'authorization': authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get
   * List contractor by id.
   * @returns ContractorResponse Successful Response
   * @throws ApiError
   */
  public static contractorRoutesGet({
    id,
    includeEquipment = false,
    authorization,
  }: {
    id: string,
    includeEquipment?: boolean,
    authorization?: string,
  }): CancelablePromise<ContractorResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/contractor/{id}',
      path: {
        'id': id,
      },
      headers: {
        'authorization': authorization,
      },
      query: {
        'include_equipment': includeEquipment,
      },
      errors: {
        404: `Not Found`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Update
   * Updates a contractor by id.
   * @returns ContractorResponse Successful Response
   * @throws ApiError
   */
  public static contractorRoutesUpdate({
    id,
    requestBody,
    authorization,
  }: {
    id: string,
    requestBody: ContractorUpdateRequest,
    authorization?: string,
  }): CancelablePromise<ContractorResponse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/contractor/{id}',
      path: {
        'id': id,
      },
      headers: {
        'authorization': authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not Found`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Delete
   * Delete a contractor by id.
   * @returns void
   * @throws ApiError
   */
  public static contractorRoutesDelete({
    id,
    authorization,
  }: {
    id: string,
    authorization?: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/contractor/{id}',
      path: {
        'id': id,
      },
      headers: {
        'authorization': authorization,
      },
      errors: {
        404: `Not Found`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Contractor Filters
   * Get contractor filters.
   * @returns ContractorFilterResponse Successful Response
   * @throws ApiError
   */
  public static contractorRoutesGetContractorFilters({
    requestBody,
    authorization,
  }: {
    requestBody: ContractorFilterRequest,
    authorization?: string,
  }): CancelablePromise<ContractorFilterResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/contractor/filters',
      headers: {
        'authorization': authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not Found`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Current Contractor
   * Get current contractor.
   * @returns ContractorCurrentResponse Successful Response
   * @throws ApiError
   */
  public static contractorRoutesGetCurrentContractor({
    authorization,
  }: {
    authorization?: string,
  }): CancelablePromise<ContractorCurrentResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/contractor/current/contractor',
      headers: {
        'authorization': authorization,
      },
      errors: {
        404: `Not Found`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Invite User
   * Allow contractor user to send out an invite to email to join its contractor.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static contractorRoutesInviteUser({
    requestBody,
    authorization,
  }: {
    requestBody: ContractorUserInviteRequest,
    authorization?: string,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/contractor/invite-internal',
      headers: {
        'authorization': authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Invite Contractor User
   * Allows Ops user to send out an invite to a external email to become contractor user.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static contractorRoutesInviteContractorUser({
    requestBody,
    authorization,
  }: {
    requestBody: ContractorUserInviteRequest,
    authorization?: string,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/contractor/invite',
      headers: {
        'authorization': authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
