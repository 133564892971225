import announcements from '@shared/locales/en/announcements.json';
import commonActions from '@shared/locales/en/commonActions.json';
import commonEquipment from '@shared/locales/en/commonEquipment.json';
import commonFormValidation from '@shared/locales/en/commonFormValidation.json';
import commonJob from '@shared/locales/en/commonJob.json';
import commonLabels from '@shared/locales/en/commonLabels.json';
import commonMessages from '@shared/locales/en/commonMessages.json';
import commonPrograms from '@shared/locales/en/commonPrograms.json';
import commonTasks from '@shared/locales/en/commonTasks.json';
import completeAcknowledgmentForm from '@shared/locales/en/completeAcknowledgmentForm.json';
import completeProgramForms from '@shared/locales/en/completeProgramForms.json';
import completeProjectForms from '@shared/locales/en/completeProjectForms.json';
import confirmInstallDates from '@shared/locales/en/confirmInstallDates.json';
import confirmPostInstallDetails from '@shared/locales/en/confirmPostInstallDetails.json';
import confirmPostInstallDetailsElectrical from '@shared/locales/en/confirmPostInstallDetailsElectrical.json';
import confirmPostInstallDetailsGasFurnace from '@shared/locales/en/confirmPostInstallDetailsGasFurnace.json';
import confirmPostInstallDetailsHeatPump from '@shared/locales/en/confirmPostInstallDetailsHeatPump.json';
import confirmPostInstallDetailsWaterHeater from '@shared/locales/en/confirmPostInstallDetailsWaterHeater.json';
import confirmPreInstallDetails from '@shared/locales/en/confirmPreInstallDetails.json';
import confirmProjectFinalCosts from '@shared/locales/en/confirmProjectFinalCosts.json';
import contractor from '@shared/locales/en/contractor.json';
import createScopeOfWork from '@shared/locales/en/createScopeOfWork.json';
import determineShareRebateAmount from '@shared/locales/en/determineShareRebateAmount.json';
import energyProviders from '@shared/locales/en/energyProviders.json';
import envelopePart from '@shared/locales/en/envelopePart.json';
import error from '@shared/locales/en/error.json';
import jobCreate from '@shared/locales/en/jobCreate.json';
import jobDetails from '@shared/locales/en/jobDetails.json';
import jobsList from '@shared/locales/en/jobsList.json';
import login from '@shared/locales/en/login.json';
import postCASTesting from '@shared/locales/en/postCASTesting.json';
import preCASTesting from '@shared/locales/en/preCASTesting.json';
import preHomeSafetyAssessment from '@shared/locales/en/preHomeSafetyAssessment.json';
import preLimitedHVACAssessment from '@shared/locales/en/preLimitedHVACAssessment.json';
import programPolicies from '@shared/locales/en/programPolicies.json';
import provideAirSealingDetails from '@shared/locales/en/provideAirSealingDetails.json';
import provideDemographicInfo from '@shared/locales/en/provideDemographicInfo.json';
import provideEnergySourcesAndUtilities from '@shared/locales/en/provideEnergySourcesAndUtilities.json';
import provideEnergyUses from '@shared/locales/en/provideEnergyUses.json';
import provideEnvelopeDetailsBottom from '@shared/locales/en/provideEnvelopeDetailsBottom.json';
import provideEnvelopeDetailsDucts from '@shared/locales/en/provideEnvelopeDetailsDucts.json';
import provideEnvelopeDetailsExterior from '@shared/locales/en/provideEnvelopeDetailsExterior.json';
import provideEnvelopeDetailsTop from '@shared/locales/en/provideEnvelopeDetailsTop.json';
import provideEquipmentInstalledDetails from '@shared/locales/en/provideEquipmentInstalledDetails.json';
import provideEquipmentRemovedDetails from '@shared/locales/en/provideEquipmentRemovedDetails.json';
import provideExistingEquipmentAndSources from '@shared/locales/en/provideExistingEquipmentAndSources.json';
import provideHeatPumpInstalledDetails from '@shared/locales/en/provideHeatPumpInstalledDetails.json';
import provideHomeownerDetails from '@shared/locales/en/provideHomeownerDetails.json';
import provideIncomeEstimate from '@shared/locales/en/provideIncomeEstimate.json';
import provideInsulationDetails from '@shared/locales/en/provideInsulationDetails.json';
import provideOtherDetails from '@shared/locales/en/provideOtherDetails.json';
import provideOtherHomeownerDetails from '@shared/locales/en/provideOtherHomeownerDetails.json';
import providePreInstallDetails from '@shared/locales/en/providePreInstallDetails.json';
import provideServices from '@shared/locales/en/provideServices.json';
import provideSiteDetails from '@shared/locales/en/provideSiteDetails.json';
import provideUtilityCredentials from '@shared/locales/en/provideUtilityCredentials.json';
import provideWaterHeaterInstalledDetails from '@shared/locales/en/provideWaterHeaterInstalledDetails.json';
import recordCASTestingResults from '@shared/locales/en/recordCASTestingResults.json';
import reviewBillImpactEstimate from '@shared/locales/en/reviewBillImpactEstimate.json';
import reviewDeemedRange from '@shared/locales/en/reviewDeemedRange.json';
import reviewManualJReport from '@shared/locales/en/reviewManualJReport.json';
import reviewQualityInstallReport from '@shared/locales/en/reviewQualityInstallReport.json';
import reviewRebateRange from '@shared/locales/en/reviewRebateRange.json';
import sendPayment from '@shared/locales/en/sendPayment.json';
import solicitHomeownerInfo from '@shared/locales/en/solicitHomeownerInfo.json';
import submitFinalDocs from '@shared/locales/en/submitFinalDocs.json';
import submitRebateDetails from '@shared/locales/en/submitRebateDetails.json';
import uploadFinalInvoice from '@shared/locales/en/uploadFinalInvoice.json';
import uploadManualJReport from '@shared/locales/en/uploadManualJReport.json';
import uploadPhotosOfInstalledMeasures from '@shared/locales/en/uploadPhotosOfInstalledMeasures.json';
import uploadProjectScopeOfWork from '@shared/locales/en/uploadProjectScopeOfWork.json';
import uploadProposedScopeOfWork from '@shared/locales/en/uploadProposedScopeOfWork.json';
import uploadUtilityBills from '@shared/locales/en/uploadUtilityBills.json';
import userPreferences from '@shared/locales/en/userPreferences.json';
import verifyIncomeQualification from '@shared/locales/en/verifyIncomeQualification.json';
import { createI18n } from 'vue-i18n';

export const sharedEnI18n = {
  ...contractor,
  ...postCASTesting,
  ...recordCASTestingResults,
  ...confirmPostInstallDetails,
  ...confirmPostInstallDetailsHeatPump,
  ...confirmPostInstallDetailsGasFurnace,
  ...confirmPostInstallDetailsElectrical,
  ...confirmPostInstallDetailsWaterHeater,
  ...preCASTesting,
  ...preHomeSafetyAssessment,
  ...preLimitedHVACAssessment,
  ...announcements,
  ...provideEnergySourcesAndUtilities,
  ...provideServices,
  ...jobDetails,
  ...jobsList,
  ...jobCreate,
  ...provideEnergyUses,
  ...confirmPreInstallDetails,
  ...providePreInstallDetails,
  ...provideHomeownerDetails,
  ...provideOtherHomeownerDetails,
  ...provideOtherDetails,
  ...provideSiteDetails,
  ...provideDemographicInfo,
  ...reviewBillImpactEstimate,
  ...provideEquipmentRemovedDetails,
  ...provideEquipmentInstalledDetails,
  ...provideExistingEquipmentAndSources,
  ...provideHeatPumpInstalledDetails,
  ...provideWaterHeaterInstalledDetails,
  ...provideIncomeEstimate,
  ...verifyIncomeQualification,
  ...reviewRebateRange,
  ...submitRebateDetails,
  ...confirmInstallDates,
  ...determineShareRebateAmount,
  ...userPreferences,
  ...uploadFinalInvoice,
  ...uploadPhotosOfInstalledMeasures,
  ...uploadProjectScopeOfWork,
  ...uploadProposedScopeOfWork,
  ...submitFinalDocs,
  ...sendPayment,
  ...confirmProjectFinalCosts,
  ...login,
  ...error,
  ...energyProviders,
  ...completeAcknowledgmentForm,
  ...completeProgramForms,
  ...completeProjectForms,
  ...provideEnvelopeDetailsDucts,
  ...uploadManualJReport,
  ...reviewManualJReport,
  ...provideEnvelopeDetailsTop,
  ...provideEnvelopeDetailsBottom,
  ...provideEnvelopeDetailsExterior,
  ...provideAirSealingDetails,
  ...provideInsulationDetails,
  ...envelopePart,
  ...reviewDeemedRange,
  ...programPolicies,
  ...solicitHomeownerInfo,
  ...uploadUtilityBills,
  ...reviewQualityInstallReport,
  ...provideUtilityCredentials,
  ...createScopeOfWork,
  ...commonActions,
  ...commonEquipment,
  ...commonFormValidation,
  ...commonJob,
  ...commonLabels,
  ...commonMessages,
  ...commonPrograms,
  ...commonTasks
};

export default createI18n({
  legacy: false,
  locale: 'en', // default language
  fallbackLocale: 'en', // fallback language in case a translation is not found
  messages: {
    en: {
      sharedEnI18n
    }
  }
});
