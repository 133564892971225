import pinia from '@/store/store';
import * as Sentry from '@sentry/vue';
import { firebaseApp } from '@shared/plugins/firebase';
import logRocket from '@shared/plugins/logRocket';
import openApiClient from '@shared/plugins/openApiClient';
import FloatingVue from 'floating-vue';
import { LDPlugin } from 'launchdarkly-vue-client-sdk';
import smoothscroll from 'smoothscroll-polyfill';
import { createApp } from 'vue';
import VueGtag from 'vue-gtag';
import { VueFire, VueFireAuth } from 'vuefire';

import App from './App.vue';
import i18n from './i18n';
import router from './router';

// polyfill for smooth scroll animation behavior between pages for iOS
smoothscroll.polyfill();

const app = createApp(App);

app.use(openApiClient, { baseUrl: import.meta.env.VITE_OPEN_API_BASE_URL });
app.use(pinia);

if (import.meta.env.VITE_TRACKING_ENABLED == 'true') {
  app.use(logRocket, { appId: import.meta.env.VITE_LOG_ROCKET_APP_ID });
}

app.use(LDPlugin, {
  clientSideID: import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID
});

//Skip the Sentry initialization if the environment when developing locally
if (import.meta.env.VITE_ENV_NAME !== 'DEV') {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllInputs: false,
        maskAllText: false,
        blockAllMedia: false
      })
    ],
    environment: import.meta.env.MODE,
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'app-dev.sealed.dev',
      'app-sandbox1.sealed.dev',
      'app-sandbox2.sealed.dev',
      'app-demo.sealed.dev',
      'app-stg.sealed.dev',
      'app.sealed.com'
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
app.use(router);
app.use(FloatingVue);

app.use(
  VueGtag,
  {
    appName: 'S3 Contractor',
    pageTrackerScreenviewEnabled: true,
    config: { id: import.meta.env.VITE_S3_CONTRACTOR_GA_MEASUREMENT_ID },
    enabled: import.meta.env.VITE_GA_TRACKING_ENABLED == 'true'
  },
  router
);

app.use(VueFire, {
  firebaseApp,
  modules: [VueFireAuth()]
});

app.use(i18n);
app.mount('#app');
