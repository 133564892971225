import { OpenAPI } from '@shared/generated';
import { NotificationType } from '@shared/types/Notification';
import pushNotification from '@shared/utils/notification';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import type { App } from 'vue';
import { getCurrentUser } from 'vuefire';

const getToken = async () => {
  const user = await getCurrentUser();
  const accessToken = (await user?.getIdToken()) || '';
  return accessToken;
};

export default {
  install: (app: App, { baseUrl }: { baseUrl: string }) => {
    OpenAPI.TOKEN = getToken;
    OpenAPI.BASE = baseUrl;
  }
};

axios.interceptors.request.use(async (config) => {
  config.headers = config.headers || {};
  config.headers['X-REQUEST-ID'] = `${uuidv4()}`;
  return config;
});

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      const token = await getToken();
      // Only do this if we have a token, otherwise we are already on the login page
      if (token) {
        pushNotification({
          type: NotificationType.DANGER,
          message: 'Invalid user permissions, redirecting to login.'
        });
        await new Promise((resolve) => setTimeout(resolve, 5000));

        return (window.location.href = window.location.origin + '/logout');
      }
    }
    if (
      error.response?.status !== 401 &&
      !error.config.headers['Ignore-Errors']
        ?.split(',')
        .includes(error.response?.status.toString())
    ) {
      // Show error massage on api failure. In the future we might want to add a way to mark
      // specific APIs as critical to page rendering and set appState.error to true conditionally here

      pushNotification({
        type: NotificationType.DANGER,
        message: error.response?.data?.detail ?? 'Unexpected error occurred.'
      });
    }
    return Promise.reject(error);
  }
);
