import FullWidthLayout from '@/layouts/FullWidthLayout.vue';
import SidebarLayout from '@/layouts/SidebarLayout.vue';

const routes = [
  {
    path: '/',
    name: 'JobsList',
    meta: {
      requiresAuth: true,
      jobCreateBtn: true,
      title: 'Jobs List',
      layout: SidebarLayout
    },
    component: () => import('@/views/JobsList.vue')
  },
  {
    path: '/claimed-jobs',
    name: 'ClaimedJobs',
    meta: {
      requiresAuth: true,
      jobCreateBtn: true,
      title: 'Claimed Jobs',
      layout: SidebarLayout
    },
    component: () => import('@/views/ClaimedJobs.vue')
  },
  {
    path: '/jobs',
    name: 'OldJobsList',
    meta: {
      requiresAuth: true,
      jobCreateBtn: true,
      title: 'Jobs List'
    },
    redirect: { name: 'JobsList' }
  },
  {
    path: '/code/:jobShortCode',
    name: 'JobShortCodeRedirect',
    meta: { requiresAuth: true, layout: SidebarLayout },
    component: () => import('@/components/JobShortCodeRedirect.vue')
  },
  {
    path: '/create',
    name: 'JobCreate',
    meta: {
      requiresAuth: true,
      layout: FullWidthLayout,
      title: 'New Job'
    },
    component: () => import('@/views/JobCreate.vue')
  },
  {
    path: '/:jobId',
    meta: {
      requiresAuth: true,
      jobCreateBtn: true,
      isInternal: false,
      layout: SidebarLayout
    },
    component: () => import('@/views/JobDetail.vue'),
    children: [
      {
        path: '/:jobId',
        name: 'JobDetail',
        component: () => import('@/components/JobDetail/RebateAmount.vue')
      },
      {
        path: '/:jobId/basic-details',
        name: 'basicDetails',
        component: () => import('@/components/JobDetail/BasicDetails.vue')
      },
      {
        path: '/:jobId/energy-source-details',
        name: 'energySourcesAndUtilities',
        component: () =>
          import('@/components/JobDetail/EnergySourcesAndUtilities.vue')
      },
      {
        path: '/:jobId/services',
        name: 'services',
        component: () => import('@/components/JobDetail/Services.vue')
      },
      {
        path: '/:jobId/install-details',
        name: 'installDetails',
        component: () => import('@/components/JobDetail/InstallDetails.vue')
      }
    ]
  },
  {
    path: '/:jobId/ineligible',
    name: 'Ineligible',
    meta: {
      requiresAuth: true,
      layout: FullWidthLayout
    },
    component: () => import('@/views/Ineligible.vue')
  }
];

export default routes;
