<template>
  <ErrorCommon
    :heading="t('error.not_found.heading')"
    :description="t('error.not_found.description')"
  >
    <template #image>
      <img
        :src="missingHome"
        alt="Sealed"
        class="mb-4"
      />
    </template>
  </ErrorCommon>
</template>

<script setup lang="ts">
import missingHome from '@shared/assets/images/missing-home.svg';
import ErrorCommon from '@shared/components/Error/ErrorCommon.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>
