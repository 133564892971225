import exitTaskViewModal from '@/locales/en/exitTaskViewModal.json';
import homeownerExperience from '@/locales/en/homeownerExperience.json';
import { sharedEnI18n } from '@shared/i18n';
import { createI18n } from 'vue-i18n';

export default createI18n({
  legacy: false,
  locale: 'en', // default language
  fallbackLocale: 'en', // fallback language in case a translation is not found
  messages: {
    en: {
      ...sharedEnI18n,
      ...exitTaskViewModal,
      ...homeownerExperience
    }
  }
});
