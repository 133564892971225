/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JobCancellationRequest } from '../models/JobCancellationRequest';
import type { JobCloneRequest } from '../models/JobCloneRequest';
import type { JobCreateRequest } from '../models/JobCreateRequest';
import type { JobFilterRequest } from '../models/JobFilterRequest';
import type { JobFilterResponse } from '../models/JobFilterResponse';
import type { JobInitialEligibilityResponse } from '../models/JobInitialEligibilityResponse';
import type { JobResponse } from '../models/JobResponse';
import type { JobResponseForHomeowner } from '../models/JobResponseForHomeowner';
import type { JobResponseWithRebateEstimate } from '../models/JobResponseWithRebateEstimate';
import type { JobResponseWithTask } from '../models/JobResponseWithTask';
import type { JobSearchResponse } from '../models/JobSearchResponse';
import type { JobsSummary } from '../models/JobsSummary';
import type { JobsSummaryWithActionRequired } from '../models/JobsSummaryWithActionRequired';
import type { JobStage } from '../models/JobStage';
import type { PaginatedResponse_JobResponse_ } from '../models/PaginatedResponse_JobResponse_';
import type { PaginatedResponse_JobResponseWithRebateEstimate_ } from '../models/PaginatedResponse_JobResponseWithRebateEstimate_';
import type { SortByOptions } from '../models/SortByOptions';
import type { SortDirectionOptions } from '../models/SortDirectionOptions';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class JobRoutesRepository {
  /**
   * Fetch All Jobs
   * Lists all jobs.
   *
   * Endpoint is paginated and filterable. Will show only jobs for a contractor if contractor is in context.
   * @returns PaginatedResponse_JobResponseWithRebateEstimate_ Successful Response
   * @throws ApiError
   */
  public static jobRoutesFetchAllJobs({
    contractorId,
    programId,
    isCanceled,
    isEligible,
    isClaimedJobs,
    stage,
    opsUser,
    contractorUser,
    hasContractorTaskException,
    hasOpsTaskException,
    contractorSubmittedBy,
    pageNumber = 1,
    perPage = 25,
    sortBy = 'created_at',
    sortDirection = 'asc',
    authorization,
  }: {
    contractorId?: (Array<string> | null),
    programId?: (Array<string> | null),
    isCanceled?: (boolean | null),
    isEligible?: (Array<boolean> | null),
    isClaimedJobs?: (boolean | null),
    stage?: (Array<JobStage> | null),
    opsUser?: (Array<string> | null),
    contractorUser?: (Array<string> | null),
    hasContractorTaskException?: (boolean | null),
    hasOpsTaskException?: (boolean | null),
    contractorSubmittedBy?: (Array<string> | null),
    pageNumber?: number,
    perPage?: number,
    sortBy?: SortByOptions,
    sortDirection?: SortDirectionOptions,
    authorization?: string,
  }): CancelablePromise<PaginatedResponse_JobResponseWithRebateEstimate_> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/jobs',
      headers: {
        'authorization': authorization,
      },
      query: {
        'contractor_id': contractorId,
        'program_id': programId,
        'is_canceled': isCanceled,
        'is_eligible': isEligible,
        'is_claimed_jobs': isClaimedJobs,
        'stage': stage,
        'ops_user': opsUser,
        'contractor_user': contractorUser,
        'has_contractor_task_exception': hasContractorTaskException,
        'has_ops_task_exception': hasOpsTaskException,
        'contractor_submitted_by': contractorSubmittedBy,
        'page_number': pageNumber,
        'per_page': perPage,
        'sort_by': sortBy,
        'sort_direction': sortDirection,
      },
      errors: {
        404: `Not Found`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Create Job
   * Adds a new job and site contact.
   * @returns JobResponseWithTask Successful Response
   * @throws ApiError
   */
  public static jobRoutesCreateJob({
    requestBody,
    authorization,
  }: {
    requestBody: JobCreateRequest,
    authorization?: string,
  }): CancelablePromise<JobResponseWithTask> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/jobs',
      headers: {
        'authorization': authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Fetch All Jobs Internal
   * Lists all jobs for internal app. No rebate estimates included.
   *
   * Endpoint is paginated and filterable.
   * @returns PaginatedResponse_JobResponse_ Successful Response
   * @throws ApiError
   */
  public static jobRoutesFetchAllJobsInternal({
    contractorId,
    programId,
    isCanceled,
    isEligible,
    isClaimedJobs,
    stage,
    opsUser,
    contractorUser,
    hasContractorTaskException,
    hasOpsTaskException,
    contractorSubmittedBy,
    pageNumber = 1,
    perPage = 25,
    sortBy = 'created_at',
    sortDirection = 'asc',
    authorization,
  }: {
    contractorId?: (Array<string> | null),
    programId?: (Array<string> | null),
    isCanceled?: (boolean | null),
    isEligible?: (Array<boolean> | null),
    isClaimedJobs?: (boolean | null),
    stage?: (Array<JobStage> | null),
    opsUser?: (Array<string> | null),
    contractorUser?: (Array<string> | null),
    hasContractorTaskException?: (boolean | null),
    hasOpsTaskException?: (boolean | null),
    contractorSubmittedBy?: (Array<string> | null),
    pageNumber?: number,
    perPage?: number,
    sortBy?: SortByOptions,
    sortDirection?: SortDirectionOptions,
    authorization?: string,
  }): CancelablePromise<PaginatedResponse_JobResponse_> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/jobs/internal',
      headers: {
        'authorization': authorization,
      },
      query: {
        'contractor_id': contractorId,
        'program_id': programId,
        'is_canceled': isCanceled,
        'is_eligible': isEligible,
        'is_claimed_jobs': isClaimedJobs,
        'stage': stage,
        'ops_user': opsUser,
        'contractor_user': contractorUser,
        'has_contractor_task_exception': hasContractorTaskException,
        'has_ops_task_exception': hasOpsTaskException,
        'contractor_submitted_by': contractorSubmittedBy,
        'page_number': pageNumber,
        'per_page': perPage,
        'sort_by': sortBy,
        'sort_direction': sortDirection,
      },
      errors: {
        404: `Not Found`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Fetch Jobs Summary
   * Returns the action required jobs for the contractor job list alongside with the total count per bucket.
   * @returns JobsSummaryWithActionRequired Successful Response
   * @throws ApiError
   */
  public static jobRoutesFetchJobsSummary({
    contractorId,
    programId,
    isCanceled,
    isEligible,
    isClaimedJobs,
    stage,
    opsUser,
    contractorUser,
    hasContractorTaskException,
    hasOpsTaskException,
    contractorSubmittedBy,
    pageNumber = 1,
    perPage = 25,
    sortBy = 'created_at',
    sortDirection = 'asc',
    authorization,
  }: {
    contractorId?: (Array<string> | null),
    programId?: (Array<string> | null),
    isCanceled?: (boolean | null),
    isEligible?: (Array<boolean> | null),
    isClaimedJobs?: (boolean | null),
    stage?: (Array<JobStage> | null),
    opsUser?: (Array<string> | null),
    contractorUser?: (Array<string> | null),
    hasContractorTaskException?: (boolean | null),
    hasOpsTaskException?: (boolean | null),
    contractorSubmittedBy?: (Array<string> | null),
    pageNumber?: number,
    perPage?: number,
    sortBy?: SortByOptions,
    sortDirection?: SortDirectionOptions,
    authorization?: string,
  }): CancelablePromise<JobsSummaryWithActionRequired> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/jobs/summary',
      headers: {
        'authorization': authorization,
      },
      query: {
        'contractor_id': contractorId,
        'program_id': programId,
        'is_canceled': isCanceled,
        'is_eligible': isEligible,
        'is_claimed_jobs': isClaimedJobs,
        'stage': stage,
        'ops_user': opsUser,
        'contractor_user': contractorUser,
        'has_contractor_task_exception': hasContractorTaskException,
        'has_ops_task_exception': hasOpsTaskException,
        'contractor_submitted_by': contractorSubmittedBy,
        'page_number': pageNumber,
        'per_page': perPage,
        'sort_by': sortBy,
        'sort_direction': sortDirection,
      },
      errors: {
        404: `Not Found`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Fetch Jobs Summary Internal
   * Returns total count per bucket for the internal jobs list.
   * @returns JobsSummary Successful Response
   * @throws ApiError
   */
  public static jobRoutesFetchJobsSummaryInternal({
    contractorId,
    programId,
    isCanceled,
    isEligible,
    isClaimedJobs,
    stage,
    opsUser,
    contractorUser,
    hasContractorTaskException,
    hasOpsTaskException,
    contractorSubmittedBy,
    pageNumber = 1,
    perPage = 25,
    sortBy = 'created_at',
    sortDirection = 'asc',
    authorization,
  }: {
    contractorId?: (Array<string> | null),
    programId?: (Array<string> | null),
    isCanceled?: (boolean | null),
    isEligible?: (Array<boolean> | null),
    isClaimedJobs?: (boolean | null),
    stage?: (Array<JobStage> | null),
    opsUser?: (Array<string> | null),
    contractorUser?: (Array<string> | null),
    hasContractorTaskException?: (boolean | null),
    hasOpsTaskException?: (boolean | null),
    contractorSubmittedBy?: (Array<string> | null),
    pageNumber?: number,
    perPage?: number,
    sortBy?: SortByOptions,
    sortDirection?: SortDirectionOptions,
    authorization?: string,
  }): CancelablePromise<JobsSummary> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/jobs/summary-internal',
      headers: {
        'authorization': authorization,
      },
      query: {
        'contractor_id': contractorId,
        'program_id': programId,
        'is_canceled': isCanceled,
        'is_eligible': isEligible,
        'is_claimed_jobs': isClaimedJobs,
        'stage': stage,
        'ops_user': opsUser,
        'contractor_user': contractorUser,
        'has_contractor_task_exception': hasContractorTaskException,
        'has_ops_task_exception': hasOpsTaskException,
        'contractor_submitted_by': contractorSubmittedBy,
        'page_number': pageNumber,
        'per_page': perPage,
        'sort_by': sortBy,
        'sort_direction': sortDirection,
      },
      errors: {
        404: `Not Found`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Job Filters
   * Get job filters.
   * @returns JobFilterResponse Successful Response
   * @throws ApiError
   */
  public static jobRoutesGetJobFilters({
    requestBody,
    authorization,
  }: {
    requestBody: JobFilterRequest,
    authorization?: string,
  }): CancelablePromise<JobFilterResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/jobs/filters',
      headers: {
        'authorization': authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not Found`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Job Search Inputs
   * Gets a list of jobs with the response limited to omni-search relevant fields.
   * @returns JobSearchResponse Successful Response
   * @throws ApiError
   */
  public static jobRoutesJobSearchInputs({
    authorization,
  }: {
    authorization?: string,
  }): CancelablePromise<Array<JobSearchResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/jobs/search',
      headers: {
        'authorization': authorization,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Check Initial Eligibility
   * Find eligible programs based on geography, program accepting submission date, and programs associated with contractor and find any existing jobs with the same location.
   * @returns JobInitialEligibilityResponse Successful Response
   * @throws ApiError
   */
  public static jobRoutesCheckInitialEligibility({
    googlePlaceId,
    authorization,
  }: {
    googlePlaceId: string,
    authorization?: string,
  }): CancelablePromise<JobInitialEligibilityResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/jobs/initial-eligibility/{google_place_id}',
      path: {
        'google_place_id': googlePlaceId,
      },
      headers: {
        'authorization': authorization,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Job
   * Get a job by id.
   * @returns JobResponseWithRebateEstimate Successful Response
   * @throws ApiError
   */
  public static jobRoutesGetJob({
    jobId,
    authorization,
  }: {
    jobId: string,
    authorization?: string,
  }): CancelablePromise<JobResponseWithRebateEstimate> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/jobs/{job_id}',
      path: {
        'job_id': jobId,
      },
      headers: {
        'authorization': authorization,
      },
      errors: {
        404: `Not Found`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Job Homeowner
   * Get job for homeowner user.
   * @returns JobResponseForHomeowner Successful Response
   * @throws ApiError
   */
  public static jobRoutesGetJobHomeowner({
    id,
    authorization,
  }: {
    id: string,
    authorization?: string,
  }): CancelablePromise<JobResponseForHomeowner> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/jobs/homeowner/{id}',
      path: {
        'id': id,
      },
      headers: {
        'authorization': authorization,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Confirm Tasks Done Homeowner
   * Endpoint to send an email to contractor when homeowner is done with their tasks.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static jobRoutesConfirmTasksDoneHomeowner({
    jobId,
    authorization,
  }: {
    jobId: string,
    authorization?: string,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/jobs/homeowner/confirm-tasks-done/{job_id}',
      path: {
        'job_id': jobId,
      },
      headers: {
        'authorization': authorization,
      },
      errors: {
        404: `Not Found`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Job By Short Code
   * Get a job by short code.
   * @returns JobResponse Successful Response
   * @throws ApiError
   */
  public static jobRoutesGetJobByShortCode({
    shortCode,
    authorization,
  }: {
    shortCode: string,
    authorization?: string,
  }): CancelablePromise<JobResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/jobs/code/{short_code}',
      path: {
        'short_code': shortCode,
      },
      headers: {
        'authorization': authorization,
      },
      errors: {
        404: `Not Found`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Cancel Job
   * Cancel a job by id.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static jobRoutesCancelJob({
    jobId,
    requestBody,
    authorization,
  }: {
    jobId: string,
    requestBody: JobCancellationRequest,
    authorization?: string,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/jobs/{job_id}/cancel',
      path: {
        'job_id': jobId,
      },
      headers: {
        'authorization': authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Restore Job
   * Restore a job by id.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static jobRoutesRestoreJob({
    jobId,
    authorization,
  }: {
    jobId: string,
    authorization?: string,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/jobs/{job_id}/restore',
      path: {
        'job_id': jobId,
      },
      headers: {
        'authorization': authorization,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Claim Job
   * Assign job to logged user.
   * @returns JobResponse Successful Response
   * @throws ApiError
   */
  public static jobRoutesClaimJob({
    id,
    authorization,
  }: {
    id: string,
    authorization?: string,
  }): CancelablePromise<JobResponse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/jobs/{id}/claim-job',
      path: {
        'id': id,
      },
      headers: {
        'authorization': authorization,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Set Job As Deemed
   * Sets job as deemed.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static jobRoutesSetJobAsDeemed({
    id,
    authorization,
  }: {
    id: string,
    authorization?: string,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/jobs/{id}/set-job-as-deemed',
      path: {
        'id': id,
      },
      headers: {
        'authorization': authorization,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Regenerate And Resend Job Documents
   * Regenerate and resend job documents.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static jobRoutesRegenerateAndResendJobDocuments({
    jobId,
    taskName,
    recreate = false,
    authorization,
  }: {
    jobId: string,
    taskName: string,
    recreate?: boolean,
    authorization?: string,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/jobs/{job_id}/regenerate-documents',
      path: {
        'job_id': jobId,
      },
      headers: {
        'authorization': authorization,
      },
      query: {
        'task_name': taskName,
        'recreate': recreate,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Clone Job
   * Clones an existing job under a new program.
   * @returns JobResponseWithTask Successful Response
   * @throws ApiError
   */
  public static jobRoutesCloneJob({
    requestBody,
    authorization,
  }: {
    requestBody: JobCloneRequest,
    authorization?: string,
  }): CancelablePromise<JobResponseWithTask> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/jobs/{job_id}/clone',
      headers: {
        'authorization': authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
