import FullWidthLayout from '@/layouts/FullWidthLayout.vue';
import SidebarLayout from '@/layouts/SidebarLayout.vue';
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { getCurrentUser } from 'vuefire';

const redirectToHome = async () => {
  const currentUser = await getCurrentUser();
  // if the user is logged in, redirect to home page
  if (currentUser) {
    return { name: 'home' };
  }
};

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/'
  },
  {
    path: '/accept-contractor-invite',
    name: 'signup',
    meta: {
      layout: FullWidthLayout,
      title: 'Signup'
    },
    component: () => import('@/views/Signup.vue'),
    beforeEnter: [redirectToHome]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: FullWidthLayout,
      title: 'Login'
    },
    beforeEnter: [redirectToHome]
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      layout: FullWidthLayout,
      title: 'Forgot Password'
    }
  },
  {
    path: '/password-reset',
    name: 'password-reset',
    component: () => import('@/views/ResetPassword.vue'),
    meta: {
      layout: FullWidthLayout,
      title: 'Password Reset'
    },
    beforeEnter: [redirectToHome]
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/views/Logout.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: 'error',
    component: () => import('@shared/components/Error/NotFoundError.vue'),
    meta: {
      title: 'Error',
      layout: SidebarLayout
    }
  },
  {
    path: '/playwright-test-login',
    name: 'playwrightTestLogin',
    component: () => import('@/views/TestLogin.vue'),
    meta: {
      layout: FullWidthLayout,
      title: 'Playwright Test Login'
    },
    beforeEnter: (
      to: RouteLocationNormalized,
      form: RouteLocationNormalized,
      next: NavigationGuardNext
    ) => {
      // Redirect to login page if in production
      if (import.meta.env.VITE_ENV_NAME === 'PROD') {
        next({ name: 'login' });
      } else {
        next();
      }
    }
  }
];

export default routes;
