<template>
  <div class="omnisearch">
    <label class="text-white">{{ t('jobs_list.omni_search.seach_by') }}</label>
    <div class="input-wrapper">
      <input
        type="text"
        class="form-control"
        :placeholder="t('jobs_list.omni_search.placeholder')"
        aria-label="Search"
        v-model="search"
        @input="handleSearch"
        @keydown.down.prevent="focusNext"
        @keydown.up.prevent="focusPrev"
        @keydown.enter.prevent="onEnter"
        @keydown.esc.prevent="handleClose"
        autofocus
        ref="searchInput"
      />
      <template v-if="search">
        <span
          class="material-symbols-outlined text-primary icon"
          @click="clearSearch"
        >
          close
        </span>
      </template>
      <template v-else>
        <span class="material-symbols-outlined text-primary icon">search</span>
      </template>
    </div>

    <ul
      class="form-control"
      v-if="search.length > 1"
    >
      <template v-if="loading">
        <li class="text-center text-small">
          <div><Spinner class="rotate" /></div>
        </li>
      </template>
      <template v-else-if="results?.length">
        <li
          v-for="(result, index) in results"
          :key="result.code"
          class="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between"
          @click="openJob(result)"
          :class="{ highlight: activeIndex === index }"
        >
          <div class="d-flex flex-column">
            <div class="d-flex mb-1">
              <h5
                class="mb-0 me-2"
                :class="{ 'text-strike': result.is_canceled }"
              >
                {{ result.full_name }}
              </h5>

              <span class="text-small">{{ result.code }}</span>
            </div>
            <div class="d-flex text-small">
              <div class="d-flex align-items-center me-2">
                <span class="material-symbols-outlined me-1 fs-1">
                  location_on
                </span>
                {{ result.address }}
              </div>
            </div>
          </div>
          <div>
            <Badge
              :label="
                result.is_canceled
                  ? t('common_labels.canceled')
                  : t(`common_job.stage_by_number.${result.stage}`)
              "
              variant="light-gray"
            />
            <Badge
              v-if="result.program"
              :label="result.program"
              class="ms-1"
              variant="info-light"
            />
          </div>
        </li>
      </template>
      <template v-else-if="search">
        <li class="text-center text-small">
          <div>
            {{ t('jobs_list.omni_search.no_results_found_warning') }}
          </div>
          <div>
            {{ t('jobs_list.omni_search.search_by_another_param_warning') }}
          </div>
        </li>
      </template>
    </ul>
  </div>
  <div
    class="omnisearch-backdrop"
    @click="handleClose"
  ></div>
</template>

<script setup lang="ts">
import Badge from '@shared/components/Badge.vue';
import Spinner from '@shared/components/Spinner.vue';
import {
  JobRoutesRepository,
  type JobSearchResponseType
} from '@shared/generated';
import Fuse from 'fuse.js';
import { nextTick, onBeforeMount, onBeforeUnmount, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const { t } = useI18n();

const emit = defineEmits(['close']);

const search = ref('');
const searchInput = ref<HTMLInputElement>();
const loading = ref(true);

onBeforeMount(() => {
  document.getElementsByTagName('body')[0].classList.add('scroll-disabled');
});

onBeforeUnmount(() => {
  document.getElementsByTagName('body')[0].classList.remove('scroll-disabled');
});

onMounted(async () => {
  // focus on next tick
  nextTick(() => {
    searchInput.value?.focus();
  });

  jobs.value = await JobRoutesRepository.jobRoutesJobSearchInputs({});

  loading.value = false;

  // if theres a search value from prior to api call, run search
  if (search.value) {
    handleSearch();
  }
});

const jobs = ref<JobSearchResponseType[]>();

const results = ref<JobSearchResponseType[]>();
const handleSearch = () => {
  if (!jobs.value || !search.value) return;

  const fuse = new Fuse(jobs.value, {
    minMatchCharLength: 2,
    threshold: 0.4,
    ignoreLocation: true,
    keys: [
      {
        name: 'full_name',
        weight: 3
      },
      'address',
      'city',
      { name: 'code', weight: 5 }
    ]
  });

  results.value = fuse.search(search.value).map((result) => result.item);
};

const activeIndex = ref(0);
const focusNext = () => {
  if (!results.value?.length) return;
  if (activeIndex.value === undefined) {
    activeIndex.value = 0;
  } else if (activeIndex.value < results.value.length - 1) {
    activeIndex.value++;
  }
};

const focusPrev = () => {
  if (activeIndex.value === undefined) {
    activeIndex.value = 0;
  } else if (activeIndex.value > 0) {
    activeIndex.value--;
  }
};

const onEnter = () => {
  if (
    activeIndex.value !== undefined &&
    results.value &&
    results.value[activeIndex.value]
  ) {
    openJob(results.value[activeIndex.value]);
  }
};

const router = useRouter();
const openJob = (job: JobSearchResponseType) => {
  router.push({ name: 'JobDetail', params: { jobId: job.id } });
  emit('close');
};

const clearSearch = () => {
  search.value = '';
  results.value = undefined;
};

const handleClose = () => {
  emit('close');
};
</script>

<style lang="scss" scoped>
$omnisearch-top-desktop: 200px;
$omnisearch-top-mobile: 100px;
$omnisearch-list-margin: 0.75rem;
$omnisearch-container-width: 700px;

// magic number to account for height of label + input
$omnisearch-input-height: 72px;

.omnisearch-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(113, 113, 111, 0.8);
  backdrop-filter: blur(3px);
  z-index: 10001; // 1 higher than header component
}
.omnisearch {
  position: fixed;
  top: $omnisearch-top-desktop;
  z-index: 10002; // 1 higher than backdrop
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  max-width: $omnisearch-container-width;
  @media screen and (max-width: 768px) {
    top: $omnisearch-top-mobile;
    max-width: calc(100vw - 1rem);
  }

  .input-wrapper {
    position: relative;
    .icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1rem;
    }
  }

  ul {
    list-style: none;
    margin: $omnisearch-list-margin 0 0;
    padding: 0;
    max-height: calc(
      100vh -
        (
          #{$omnisearch-top-desktop} + #{$omnisearch-list-margin} + #{$omnisearch-input-height} +
            1rem
        )
    );
    overflow-y: auto;
    overflow-x: hidden;

    @media screen and (max-width: 768px) {
      max-height: calc(
        100vh -
          (
            #{$omnisearch-top-mobile} + #{$omnisearch-list-margin} + #{$omnisearch-input-height} +
              1rem
          )
      );
    }

    li {
      padding: 1rem;
      border-bottom: 1px solid #e0e0e0;
      cursor: pointer;
      &:last-child {
        border-bottom: none;
      }
      &:hover,
      &.highlight {
        background-color: #f5f5f5;
      }
    }
  }
}
</style>
