<template>
  <ErrorCommon
    :heading="t('error.unexpected.heading')"
    :subheading="t('error.unexpected.subheading')"
    :description="t('error.unexpected.description')"
  >
    <template #image>
      <img
        :src="leakyHome"
        alt="Sealed"
        class="mb-4"
      />
    </template>
  </ErrorCommon>
</template>

<script setup lang="ts">
import leakyHome from '@shared/assets/images/leaky-home.svg';
import ErrorCommon from '@shared/components/Error/ErrorCommon.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>
