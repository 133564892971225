<template>
  <transition
    :name="transition"
    @before-enter="animationStart"
    @after-leave="animationComplete"
    @before-leave="scrollToTop"
    @after-enter="scrollToTop"
  >
    <slot />
  </transition>
</template>

<script lang="ts" setup>
defineProps({
  transition: {
    type: String,
    required: false,
    default: 'slide-left'
  }
});

const emit = defineEmits(['animation-complete']);

// disable scroll restoration as it messes with back/forward transitions
window.history.scrollRestoration = 'manual';

const disableScroll = () => {
  document.getElementsByTagName('body')[0].classList.add('scroll-disabled');
};

const enableScroll = () => {
  document.getElementsByTagName('body')[0].classList.remove('scroll-disabled');
};

const animationStart = () => {
  disableScroll();
  clearSelection();
};

const animationComplete = () => {
  emit('animation-complete');
  clearSelection();
};

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  enableScroll();
};

const clearSelection = () => {
  // Clear any text or form selection because sometimes the form can get
  // highlighted as it transitions if the mouse is held down
  window.getSelection()?.removeAllRanges();
};
</script>
<style lang="scss">
.scroll-disabled {
  overflow: hidden;
  height: 100%;
}
.slide-left-leave-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-right-enter-active {
  transition: 1s;
  grid-area: 1 / 1 / 2 / 2;
  /* hack to keep slides consistent height for animation to prevent janky scrolls mid transition */
  height: 10000px;
  @media screen and (max-width: 767px) {
    transition: 0.8s;
  }
}

.slide-left-leave-from,
.slide-left-enter-to,
.slide-right-leave-from,
.slide-right-enter-to {
  transform: translate(0, 0);
}
.slide-left-leave-to,
.slide-right-enter-from {
  transform: translate(-100%, 0);
}

.slide-left-enter-from,
.slide-right-leave-to {
  transform: translate(100%, 0);
}

.fade-enter-active {
  transition: 0.5s;
}
.fade-leave-active {
  transition: 0.3s;
}

.fade-enter-active,
.fade-leave-active {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  /* hack to keep slides consistent height for animation to prevent janky scrolls mid transition */
  height: 10000px;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
</style>
