<script setup lang="ts">
import { useAppStore } from '@/store/appState';
import NotFoundError from '@shared/components/Error/NotFoundError.vue';
import UnexpectedError from '@shared/components/Error/UnexpectedError.vue';
import PageTransition from '@shared/components/PageTransition.vue';
import { ErrorType } from '@shared/constants/error';
import { useLDReady } from 'launchdarkly-vue-client-sdk';
import { RouterView, useRoute } from 'vue-router';

import NoHeader from './layouts/NoHeader.vue';

const appStateStore = useAppStore();
const route = useRoute();
const ldReady = useLDReady();
</script>

<template>
  <component :is="route.meta.layout ?? NoHeader">
    <RouterView
      v-slot="{ Component }"
      :key="(route.params.jobId as string)"
    >
      <PageTransition transition="fade">
        <div>
          <template v-if="appStateStore.errorType !== null">
            <UnexpectedError
              v-if="appStateStore.errorType === ErrorType.UNEXPECTED"
            />
            <NotFoundError
              v-if="appStateStore.errorType === ErrorType.NOT_FOUND"
            />
          </template>
          <template v-else-if="appStateStore.loading || !ldReady">
            loading
          </template>

          <component
            :is="Component"
            v-else
          />
        </div>
      </PageTransition>
    </RouterView>
  </component>
  <div id="modal-target"></div>
</template>

<style lang="scss">
@import '@/assets/scss/_app.scss';
</style>
