export enum NotificationType {
  PRIMARY = 'primary',
  DANGER = 'danger',
  WARNING = 'warning',
  SUCCESS = 'success'
}
export interface Notification {
  type: NotificationType;
  message: string;
}

export const NotificationBackgroundColor: Record<NotificationType, string> = {
  [NotificationType.PRIMARY]: '#0d6efd',
  [NotificationType.DANGER]: '#dc3545',
  [NotificationType.WARNING]: '#ffc107',
  [NotificationType.SUCCESS]: '#198754'
};
