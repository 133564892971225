/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnnouncementRequest } from '../models/AnnouncementRequest';
import type { AnnouncementResponse } from '../models/AnnouncementResponse';
import type { AnnouncementWithContractorsResponse } from '../models/AnnouncementWithContractorsResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AnnouncementsRepository {
  /**
   * Get Announcements
   * Get all announcements.
   * @returns AnnouncementResponse Successful Response
   * @throws ApiError
   */
  public static announcementsGetAnnouncements({
    authorization,
  }: {
    authorization?: string,
  }): CancelablePromise<Array<AnnouncementResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/announcements',
      headers: {
        'authorization': authorization,
      },
      errors: {
        404: `Not Found`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Save
   * Creates an announcement.
   * @returns AnnouncementResponse Successful Response
   * @throws ApiError
   */
  public static announcementsSave({
    requestBody,
    authorization,
  }: {
    requestBody: AnnouncementRequest,
    authorization?: string,
  }): CancelablePromise<AnnouncementResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/announcements',
      headers: {
        'authorization': authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not Found`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Announcement By Id
   * Get announcement by id.
   * @returns AnnouncementWithContractorsResponse Successful Response
   * @throws ApiError
   */
  public static announcementsGetAnnouncementById({
    id,
    authorization,
  }: {
    id: string,
    authorization?: string,
  }): CancelablePromise<AnnouncementWithContractorsResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/announcements/{id}',
      path: {
        'id': id,
      },
      headers: {
        'authorization': authorization,
      },
      errors: {
        404: `Not Found`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Update
   * Updates announcement.
   * @returns AnnouncementResponse Successful Response
   * @throws ApiError
   */
  public static announcementsUpdate({
    id,
    requestBody,
    authorization,
  }: {
    id: string,
    requestBody: AnnouncementRequest,
    authorization?: string,
  }): CancelablePromise<AnnouncementResponse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/announcements/{id}',
      path: {
        'id': id,
      },
      headers: {
        'authorization': authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not Found`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Delete
   * Deletes announcement.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static announcementsDelete({
    id,
    authorization,
  }: {
    id: string,
    authorization?: string,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/announcements/{id}',
      path: {
        'id': id,
      },
      headers: {
        'authorization': authorization,
      },
      errors: {
        404: `Not Found`,
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Current Announcement
   * Get current announcement.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static announcementsGetCurrentAnnouncement({
    authorization,
  }: {
    authorization?: string,
  }): CancelablePromise<(AnnouncementResponse | null)> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/announcements/current/announcement',
      headers: {
        'authorization': authorization,
      },
      errors: {
        404: `Not Found`,
        422: `Validation Error`,
      },
    });
  }
}
