<template>
  <div :class="`text-center ${props.fullscreen ? 'fullscreen' : ''}`">
    <div
      class="spinner-border text-primary spinner-bg"
      role="status"
    >
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  fullscreen?: boolean;
}>();
</script>

<style lang="scss" scoped>
.fullscreen {
  margin-top: calc(30% - 130px);
}
</style>
