/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Enum for available sort direction options.
 */
export enum SortDirectionOptions {
  ASC = 'asc',
  DESC = 'desc',
}
