<template>
  <div class="d-flex flex-column fill-viewport">
    <EnvIndicator />
    <header class="homeowner-header">
      <span id="homeowner-contractor-logo"></span>
      <span class="text-small">{{ t('homeowner_experience.powered_by') }}</span>
    </header>
    <div class="position-relative flex-fill d-flex slot-layout">
      <div class="container p-0">
        <slot />
      </div>
    </div>
    <footer class="homeowner-footer">
      <img
        :src="sealedHouse"
        alt="Sealed"
      />
      <div class="text-small">
        <span class="me-2">&copy;</span>
        <span>{{ t('homeowner_experience.footer', { year }) }}</span>
      </div>
    </footer>
  </div>
</template>
<script setup lang="ts">
import sealedHouse from '@shared/assets/images/sealed_house_logo.svg';
import EnvIndicator from '@shared/components/EnvIndicator.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const year = new Date().getFullYear();
</script>
<style lang="scss" scoped>
.fill-viewport {
  background-color: var(--bs-white);
}

.homeowner-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
}

.homeowner-footer {
  display: flex;
  justify-content: space-between;
  background-color: var(--bs-secondary);
  padding: 1rem 2rem;
}
</style>
