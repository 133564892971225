/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Enum for available sort by options.
 */
export enum SortByOptions {
  JOB_NAME = 'job_name',
  CREATED_AT = 'created_at',
  CURRENT_STAGE_STARTED_AT = 'current_stage_started_at',
}
