<template>
  <div
    class="sidebar d-none d-lg-block"
    :class="{ open: sidebarOpen }"
  >
    <template v-if="contractor">
      <h5 class="fw-light px-4">
        {{
          t('common_messages.greet.welcome_back', {
            name: contractor?.name
          })
        }}
      </h5>
      <hr />
    </template>
    <ul class="d-flex flex-column h-100 px-4">
      <li
        v-for="item in props.nav"
        :key="item.label"
      >
        <router-link
          :to="{ name: item.routeName }"
          class="d-flex align-items-center"
        >
          <i class="material-symbols-outlined"> {{ item.icon }} </i>
          <span class="label">{{ item.label }}</span>
        </router-link>
      </li>
      <li
        @click="appStateStore.toggleSidebar()"
        class="mt-auto"
        role="button"
      >
        <i
          class="material-symbols-outlined"
          :class="[sidebarOpen ? 'rotate270' : 'rotate90']"
        >
          open_in_browser
        </i>
        <span class="label">Collapse Menu</span>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { useAppStore } from '@/store/appState';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  nav: {
    icon: string;
    label: string;
    routeName: string;
  }[];
}>();

const appStateStore = useAppStore();
const { sidebarOpen, contractor } = storeToRefs(appStateStore);
const { t } = useI18n();
</script>

<style lang="scss" scoped>
$header-height: 97px;
$sidebar-width: 250px;
$sidebar-paddingx: 1rem;
.sidebar {
  position: sticky;
  top: $header-height;
  height: calc(100vh - $header-height);
  width: 60px;
  padding: 2rem $sidebar-paddingx;
  background-color: white;
  transition: width 0.5s ease;
  overflow: hidden;
  &.open {
    width: $sidebar-width;
    ul li {
      i {
        margin-right: 10px;
      }
      .label {
        display: inline-block;
      }
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    width: calc($sidebar-width - $sidebar-paddingx * 2);
    overflow: hidden;
    li {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
      a,
      a:hover,
      a:focus,
      a:active {
        color: var(--bs-body-color);
      }
      i {
        height: 24px;
        width: 24px;
        overflow: hidden;
      }
      .label {
        display: none;
      }
    }
  }

  .rotate270 {
    transform: rotate(270deg);
  }
  .rotate90 {
    transform: rotate(90deg);
  }
}
</style>
