import type { Notification } from '@shared/types/Notification';
import Toastify from 'toastify-js';

const pushNotification = (notification: Notification) => {
  Toastify({
    text: notification.message,
    duration: 4500,
    close: true,
    gravity: 'top',
    position: 'right',
    style: {
      background: `var(--bs-${notification.type}`,
      color: 'black'
    }
  }).showToast();
};

export default pushNotification;
