import HomeownerLayout from '@/layouts/HomeownerLayout.vue';
import NoHeader from '@/layouts/NoHeader.vue';

const routes = [
  {
    path: '/usage-credentials-collected',
    name: 'UsageCredentialsCollected',
    meta: {
      requiresAuth: false,
      layout: NoHeader,
      title: 'Credentials Collected'
    },
    component: () => import('@/views/UsageCredentialsCollected.vue')
  },
  {
    path: '/homeowner-login',
    name: 'HomeownerLogin',
    meta: {
      requiresAuth: false,
      layout: HomeownerLayout,
      title: 'Homeowner login'
    },
    component: () => import('@/views/HomeownerLogin.vue')
  },
  {
    path: '/homeowner-logout',
    name: 'HomeownerLogout',
    meta: {
      requiresAuth: false,
      layout: HomeownerLayout,
      title: 'Homeowner login'
    },
    component: () => import('@/views/HomeownerLogout.vue')
  },
  {
    path: '/homeowner-profile/:jobId',
    name: 'HomeownerProfile',
    meta: {
      requiresHomeownerAuth: true,
      layout: HomeownerLayout,
      title: 'Homeowner Profile'
    },
    component: () => import('@/views/HomeownerProfile.vue')
  }
];

export default routes;
