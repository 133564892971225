import { logRocketEvent } from '@shared/plugins/logRocket';
import { createPinia } from 'pinia';

const pinia = createPinia();
// Global store actions tracking
pinia.use(({ store }) => {
  store.$onAction(({ name, store }) => {
    if (import.meta.env.VITE_TRACKING_ENABLED == 'true') {
      logRocketEvent(`action ${name}`, store);
    }
  });
});

export default pinia;
