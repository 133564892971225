<template>
  <span :class="className">{{ label }}</span>
</template>
<script setup lang="ts">
export type BadgeProps = {
  label: string;
  variant:
    | 'success'
    | 'warning'
    | 'secondary'
    | 'info-light'
    | 'light'
    | 'light-gray'
    | 'danger';
};
const props = defineProps<BadgeProps>();

const className = `badge opacity-75 text-black bg-${props.variant}`;
</script>
<style scoped lang="scss">
.bg-info-light {
  background-color: rgba(var(--bs-info-rgb), 0.25);
}
</style>
