<template>
  <div class="d-flex flex-column fill-viewport">
    <SiteHeader
      class="mb-0 bg-primary"
      :nav="nav"
    />
    <div class="flex-fill d-flex">
      <Sidebar :nav="nav" />
      <div class="position-relative flex-fill d-flex mt-4">
        <div class="slot-container col-12 col-xl-10">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import Sidebar from '@/components/Sidebar.vue';
import SiteHeader from '@/components/SiteHeader.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const nav = [
  {
    icon: 'star',
    label: t('jobs_list.claimed_jobs'),
    routeName: 'ClaimedJobs'
  },
  {
    icon: 'list',
    label: t('jobs_list.nav_label'),
    routeName: 'JobsList'
  },
  {
    icon: 'logout',
    label: t('common_actions.sign_out'),
    routeName: 'logout'
  }
];
</script>

<style lang="scss" scoped>
$mobile-header-height: 67px;

.slot-container {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  min-height: calc(100vh - $mobile-header-height);
  width: 100%;
  max-width: 100vw;
  @media screen and (min-width: 768px) {
    min-height: auto;
  }
}

:deep(.slot-container > div) {
  width: 100%;
}
</style>
