<template>
  <Modal
    @close="close"
    :scroll-on-open="false"
  >
    <div class="container">
      <h4 class="text-center text-gray-500 fw-bold mb-5">
        {{ props.announcement.title }}
      </h4>
      <hr />
      <div class="d-flex flex-column">
        <div class="announcement-content">{{ props.announcement.content }}</div>
        <div
          v-if="props.announcement.video"
          class="mt-3 align-self-center rounded"
        >
          <iframe
            width="560"
            height="315"
            :src="
              'https://www.youtube.com/embed/' +
              getVideoId(props.announcement.video)
            "
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <img
          class="mt-3 align-self-center rounded"
          v-if="props.announcement.image"
          :src="props.announcement.image"
        />
        <a
          class="mt-3"
          v-if="props.announcement.link"
          :href="props.announcement.link"
          target="_blank"
          >Click here to check it out!</a
        >
      </div>
      <div class="d-flex flex-column mt-3">
        <button
          class="btn btn-outline-secondary mb-4"
          @click="close"
        >
          {{ t('common_actions.close') }}
        </button>
      </div>
    </div>
  </Modal>
</template>
<script setup lang="ts">
import { Modal } from '@sealedinc/sealed-ui';
import type {
  AnnouncementRequestType,
  AnnouncementResponseType
} from '@shared/generated';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  announcement: AnnouncementResponseType | AnnouncementRequestType;
}>();

const { t } = useI18n();
const emit = defineEmits(['close']);

const close = () => {
  emit('close');
};

const getVideoId = (video: string) => {
  if (!video) {
    return null;
  }
  const videoId = video.split('v=')[1];
  return videoId;
};
</script>
<style scoped>
img {
  max-width: 560px;
}

.announcement-content {
  white-space: pre-wrap;
}
</style>
