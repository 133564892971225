<template>
  <header>
    <EnvIndicator />
    <div class="header-container">
      <div class="header-content d-flex align-items-center position-relative">
        <div
          class="menu-container position-relative d-flex align-items-center d-lg-none me-3"
          v-if="props.nav"
        >
          <span
            class="material-symbols-outlined text-light menu-icon fw-medium"
            @click="menuOpen = !menuOpen"
          >
            menu
          </span>
          <ul
            class="mobile-menu"
            :class="{ open: menuOpen }"
          >
            <template v-if="contractor">
              <li>
                <h5 class="fw-light mb-0">
                  {{
                    t('common_messages.greet.welcome_back', {
                      name: contractor?.name
                    })
                  }}
                </h5>
              </li>
              <hr />
            </template>
            <li
              v-for="item in props.nav"
              :key="item.label"
            >
              <router-link
                :to="{ name: item.routeName }"
                class="d-flex align-items-center"
                @click="() => (menuOpen = false)"
              >
                <i class="material-symbols-outlined"> {{ item.icon }} </i>
                <span class="label">{{ item.label }}</span>
              </router-link>
            </li>
          </ul>
        </div>

        <div
          @click="() => $router.push({ name: 'home' })"
          class="d-flex flex-grow-1"
        >
          <img
            class="logo"
            :src="logo"
            alt="Sealed"
          />
        </div>

        <button
          v-if="$route.meta.jobCreateBtn"
          class="add-btn btn btn-md px-3 py-2 btn-outline-light me-3"
          @click="goToJobCreate"
        >
          <span class="material-symbols-outlined">add</span>
          <span class="add-btn-text">
            {{ t('common_actions.add_new_job') }}
          </span>
        </button>
        <span
          v-if="$route.meta.jobCreateBtn"
          class="material-symbols-outlined fw-medium text-light d-lg-none me-3"
          @click="goToJobCreate"
          >add</span
        >

        <template v-if="isLoggedIn">
          <button
            class="btn btn-sm d-none d-lg-flex px-3 py-2 btn-outline-light align-items-center"
            @click="showOmniSearch = true"
          >
            <i class="material-symbols-outlined search-icon">search</i>
            {{ t('common_actions.search') }}
          </button>

          <span
            class="material-symbols-outlined fw-medium text-light d-lg-none"
            @click="showOmniSearch = true"
            >search</span
          >
        </template>
      </div>
    </div>
    <Teleport
      to="#modal-target"
      v-if="showOmniSearch"
    >
      <OmniSearch @close="showOmniSearch = false" />
    </Teleport>
    <AnnouncementModal
      v-if="announcement"
      :announcement="announcement"
      @close="announcement = null"
    />
  </header>
</template>

<script setup lang="ts">
import logo from '@/assets/images/sealed-logo.svg';
import { useAppStore } from '@/store/appState';
import AnnouncementModal from '@shared/components/AnnouncementModal.vue';
import EnvIndicator from '@shared/components/EnvIndicator.vue';
import {
  ContractorRoutesRepository,
  AnnouncementsRepository,
  type AnnouncementResponseType
} from '@shared/generated';
import { storeToRefs } from 'pinia';
import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';
import { getCurrentUser } from 'vuefire';

import OmniSearch from './OmniSearch.vue';

const props = defineProps<{
  nav?: {
    icon: string;
    label: string;
    routeName: string;
  }[];
}>();

const menuOpen = ref(false);

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const goToJobCreate = () => {
  router.push({ name: 'JobCreate' });
};

const isLoggedIn = ref(false);
const appStateStore = useAppStore();
const announcement = ref<AnnouncementResponseType | null>(null);
const { contractor } = storeToRefs(appStateStore);

onMounted(async () => {
  const currentUser = await getCurrentUser();
  isLoggedIn.value = !!currentUser;
  if (isLoggedIn.value && !appStateStore.contractor && !route.query.token) {
    const contractor =
      await ContractorRoutesRepository.contractorRoutesGetCurrentContractor({});
    appStateStore.setContractor({ id: contractor.id, name: contractor.name });
    announcement.value =
      await AnnouncementsRepository.announcementsGetCurrentAnnouncement({});
  }
});

const showOmniSearch = ref(false);

window.addEventListener('keydown', (e) => {
  if (e.metaKey && e.key === 'k') {
    e.preventDefault();
    showOmniSearch.value = true;
  }
});
</script>

<style lang="scss" scoped>
header {
  position: sticky;
  top: 0;
  border-bottom: 1px solid #bfbebb;
  z-index: 10001; //1 higher than popper component;
  margin-bottom: 1.5rem;
}

.header-container {
  padding: 1.5rem 0;
  @media screen and (min-width: 768px) {
    padding: 2rem 0;
  }
}

.header-content {
  padding: 0 1.75rem;
}

.logo {
  height: 20px;
  @media screen and (min-width: 768px) {
    width: 133px;
    height: 40px;
  }
}
.add-btn {
  display: none;
  @media screen and (min-width: 992px) {
    display: flex;
  }
  .add-btn-text {
    margin: 0 0.25rem;
  }
}

.mobile-menu {
  list-style: none;
  margin: 0;
  position: absolute;
  left: -20px;
  top: 40px;
  width: calc(100vw - 20px);
  z-index: 30;
  background-color: #fff;
  border-radius: 3px;
  padding: 1.5rem 0.75rem;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.15));
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease;
  &.open {
    display: block;
    opacity: 1;
  }
  &::before {
    content: '';
    position: absolute;
    top: -19px;
    left: 32px;
    transform: translateX(-50%);
    border: 10px solid transparent;
    border-bottom-color: #fff;
  }
  li {
    margin-bottom: 0.75rem;
    display: flex;
    align-items: center;
    &:last-child {
      margin-bottom: 0;
    }
    a,
    a:hover,
    a:focus,
    a:active {
      color: var(--bs-body-color);
    }
    i {
      margin-right: 0.75rem;
    }
  }
}
</style>
