<template>
  <div
    v-if="environment !== 'PROD'"
    class="bg-warning w-100 text-center"
  >
    <b>{{ environment }} ENV</b>
  </div>
</template>
<script setup lang="ts">
const environment = import.meta.env.VITE_ENV_NAME;
</script>
