import { identifyUser as identifyLogRocketUser } from '@shared/plugins/logRocket';
import { useLDClient } from 'launchdarkly-vue-client-sdk';
import {
  createRouter,
  createWebHistory,
  type RouteRecordRaw
} from 'vue-router';
import { getCurrentUser } from 'vuefire';

import contractors from './contractors';
import externalRoutes from './externalRoutes';
import jobs from './jobs';
import sharedRoutes from './sharedRoutes';

let allRoutes = [] as RouteRecordRaw[];
allRoutes = allRoutes.concat(contractors, jobs, sharedRoutes, externalRoutes);

const router = createRouter({
  history: createWebHistory(),
  routes: allRoutes
});

router.beforeEach(async (to) => {
  const ldClient = useLDClient();
  // routes with `meta: { requiresAuth: true }` will check for the users, others won't
  if (to.meta.requiresAuth) {
    const currentUser = await getCurrentUser();
    if (currentUser?.email) {
      identifyLogRocketUser(currentUser.email);
      await ldClient.identify({
        kind: 'user',
        key: currentUser.uid,
        name: currentUser.displayName || 'unknown',
        email: currentUser.email
      });
    }
    // if the user is not logged in, redirect to the login page
    if (!currentUser) {
      return {
        path: '/login',
        query: {
          // we keep the current path in the query so we can
          // redirect to it after login with
          // `router.push(route.query.redirect || '/')`
          redirect: to.fullPath
        }
      };
    }
  } else if (to.meta.requiresHomeownerAuth) {
    const currentUser = await getCurrentUser();
    if (!currentUser) {
      // If user is not logged in, redirect to reauth homeowner page
      return {
        path: '/homeowner-logout'
      };
    }
  }
});

router.afterEach((to, from, failure) => {
  if (failure) return false;

  if (to.meta.title)
    window.document.title = `${to.meta.title || to.name} | Sealed`;
});

router.onError((error) => {
  // On deploy if a dynamic import changes the hash will change causing the
  // dynmaic import to fail. This will reload the page and the new hash will
  // be correct.
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    window.location.reload();
  }
});

export default router;
