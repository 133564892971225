import { initializeApp } from 'firebase/app';
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  signInWithCustomToken
} from 'firebase/auth';
import { useFirebaseAuth } from 'vuefire';

export const firebaseApp = initializeApp({
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN
});

export const authService = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const auth = useFirebaseAuth()!;

  const createUser = async (email: string, password: string) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };
  const signIn = async (email: string, password: string) => {
    return signInWithEmailAndPassword(auth, email, password);
  };
  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);
  };
  const signInWithToken = async (token: string) => {
    return signInWithCustomToken(auth, token);
  };
  const signOut = async () => {
    return auth.signOut();
  };

  return {
    createUser,
    signIn,
    signInWithGoogle,
    signInWithToken,
    signOut
  };
};
